<template>
    <button v-bind="$attrs"
        class="btn btn-with-spinner"
        :class="{ 'is-busy': isBusy }"
        type="submit">
        <span class="btn-inner-text"><slot></slot></span>
        <div class="spinner-wrapper">
            <Spinner :class="spinnerColor == '' ? 'spinner-white' : spinnerColor" width="24px" height="24px"></Spinner>
        </div>
    </button>
</template>

<script lang="ts" setup>
import { Spinner } from '@/modules/core/components';

defineProps({
    isBusy: Boolean,
    spinnerColor: String
})
</script>

<style lang="scss">

.btn-with-spinner {
    position: relative;

    .spinner-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        opacity: 0;
        transition-property: opacity, visibility;
        transition-timing-function: ease;
        transition-duration: .3s;
        pointer-events: none;
    }

    .btn-inner-text {
        transition: color .3s ease;
    }

    &.is-busy {
        .btn-inner-text {
            color: transparent;
            // opacity: 0;
        }

        .spinner-wrapper {
            opacity: 1;
            visibility: visible;
        }
    }
}

</style>